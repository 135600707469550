import styles from "../styles/setting.module.scss";
import { useEffect, useState, useRef, useContext, SetStateAction } from "react";
import http from "../http";
import Page from "./page";
import Router, { useRouter } from "next/router";
import { useTheme } from "../context/ThemeContext";
import { ShowMessage } from "./ShowMessage/ShowMessage";
import { Button, Modal, QRCode, Radio, RadioChangeEvent } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

interface productType {
  alias: string;
  description: string;
  discount_price: number;
  name: string;
  price: number;
  status: number;
  timestamp: string;
  update_timestamp: string;
  uuid: string;
}

export default function Setting(props: any) {
  const router = useRouter();
  const { github_id } = router.query;
  const { visit_page, setIsLoading, userInfo, getInfo, visible } = props;
  const [active, setActive] = useState(0);
  const [orderType, setOrderType] = useState("");
  const [orderItem, setOrderItem] = useState<productType>();
  const [payType, setPayType] = useState("1");

  let username = useRef<HTMLInputElement>({ value: "" } as any);
  let [email, setEmail] = useState(userInfo.email);
  const [usernameVal, setUsernameVal] = useState(userInfo.username);
  const [password, setPassword] = useState("");
  const [passwordSure, setPasswordSure] = useState("");

  const [phone, setPhone] = useState(userInfo.phone_number);
  const themeRef = useRef<HTMLInputElement>({ value: "" } as any);
  const [showEdit, setShowEdit] = useState(false);
  const [password_iconshow, setPassword_iconshow] = useState(false);
  const [theme_iconshow, setTheme_iconshow] = useState(false);
  const [code, setCode] = useState("");
  const [count, setCount] = useState(0);
  const [page_index, setPage_index] = useState(1);
  const [page_num, setPage_num] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const [showPage, setShowPage] = useState(false);
  const [changePhone, setChangePhone] = useState(false);
  const userEdit = useRef({} as any);
  const { theme, toggleTheme } = useTheme();
  const [themeVal, setThemeVal] = useState(theme);
  const [logList, setLogList] = useState([] as any);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [QRStatus, setQRStatus] = useState<any>("loading");
  const [QRUrl, setQRUrl] = useState("loading");
  const [wxPayOpen, setWxPayOpen] = useState(false);
  const [productList, setProductList] = useState<
    Array<{
      alias: string;
      description: string;
      discount_price: number;
      name: string;
      price: number;
      status: number;
      timestamp: string;
      update_timestamp: string;
      uuid: string;
    }>
  >([]);
  useEffect(() => {
    active == 2 && getLog();
  }, [page_index]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (userInfo?.phone_number && token) {
      github_id && linkGithub();
      if (visit_page) {
        setActive(visit_page);
      }
      getPayProducts();
      return () => {
        if (timer.current) {
          clearInterval(timer.current);
        }
      };
    }
  }, []);
  const getPayProducts = () => {
    http.get("/api/v1/payment/products").then((res: any) => {
      if (res.status) {
        setProductList(res.data);
      }
    });
  };
  const bindCard = () => {
    if (payType === "2") {
      setWxPayOpen(true);
      setQRStatus("loading");
      let data = {
        product_id: orderItem?.uuid,
        pay_type: "weixin_native",
      };
      http.post("/api/v1/payment/pay", data).then((res: any) => {
        if (res.status) {
          setQRUrl(res.data);
          setQRStatus("active");
        }
      });
    } else {
      let data = {
        product_id: orderItem?.uuid,
        pay_type: "ali_native",
      };
      setIsLoading(true);
      http.post("/api/v1/payment/pay", data).then((res: any) => {
        if (res.status) {
          let divForm = document.getElementsByTagName("divform");
          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }
          const div = document.createElement("divform");
          div.innerHTML = res.data;
          document.body.appendChild(div);
          document.forms[0].acceptCharset = "UTF-8";
          document.forms[0].setAttribute("target", "_blank");

          //保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
          document.forms[0].submit();
        } else {
          ShowMessage.error(res.message);
        }
        setIsLoading(false);
      });
    }
  };
  const getCode = () => {
    let number = phone;
    if (!number) {
      ShowMessage.error("请输入手机号");
      return;
    } else if (!/^1[3456789]\d{9}$/.test(number)) {
      ShowMessage.error("请输入正确的手机号");
      return;
    }
    http
      .post("/api/v1/account/code/", { phone_number: number })
      .then((res: any) => {
        if (res.status) {
        } else {
          ShowMessage.error(res.message);
        }
      });
  };
  const initPatient = (num: number) => {
    //debugger
    setPage_index(num);
  };
  const saveInfo = () => {
    if (password === passwordSure) {
      if (password != "") {
        userEdit.current.password = password;
      }
    } else {
      ShowMessage.error("两次密码不一致");
      return;
    }
    http.put("/api/v1/account/edit", userEdit.current).then((res: any) => {
      if (res.status) {
        setShowEdit(false);
        userEdit.current = {};
        getInfo();
      } else {
        ShowMessage.error(res.message);
      }
    });
  };
  const getLog = () => {
    http
      .get(
        `/api/v1/payment/order_history?page=${page_index}&page_size=${page_num}`
      )
      .then((res: any) => {
        if (res.status) {
          setLogList(res.data.orders);
          setPageCount(res.data.total_pages);
          setCount(res.data.total_orders);
          setShowPage(res.data.total_pages > 1);
        } else {
          ShowMessage.error(res.message);
        }
      });
  };
  const goGithub = () => {
    if (userInfo.is_bind_github) return;
    http.get("/api/v1/thirdparty/github/login").then((res: any) => {
      if (res.status) {
        window.open(res.data.authorization_url);
      }
    });
  };
  const linkGithub = () => {
    http
      .post(`/api/v1/thirdparty/github/link?github_id=${github_id}`)
      .then((data: any) => {
        if (data.status) {
          router.replace("/");
        } else {
          ShowMessage.error(data.message);
        }
      });
  };
  const onChange = (e: RadioChangeEvent) => {
    setOrderType(e.target.value);
  };
  const onPayTypeChange = (e: RadioChangeEvent) => {
    setPayType(e.target.value);
  };
  return (
    <div
      className={styles.wraper}
      style={props.visible ? {} : { display: "none" }}
    >
      <ul className={styles.list}>
        <li
          className={active == 0 ? styles.active : undefined}
          onClick={() => {
            setActive(0);
          }}
        >
          <img src="/setting/home.png"></img>个人信息
        </li>
        <li
          className={active == 1 ? styles.active : undefined}
          onClick={() => {
            setActive(1);
          }}
        >
          <img src="/setting/card.png"></img>购买会员
        </li>
        <li
          className={active == 2 ? styles.active : undefined}
          onClick={() => {
            setActive(2);
            getLog();
          }}
        >
          <img src="/setting/log.png"></img>支付历史
        </li>
      </ul>
      {active == 0 && (
        <div className={styles.form}>
          <div className={styles.info_part}>
            <div className={styles.info_label}>用户名</div>
            <input
              disabled={!showEdit}
              className={!showEdit ? styles.info_input : ""}
              value={usernameVal || ""}
              onChange={(e) => {
                setUsernameVal(e.currentTarget.value);
                userEdit.current.username = e.currentTarget.value;
              }}
              placeholder="请输入您的用户名"
            />
          </div>
          <div className={styles.info_part}>
            <div className={styles.info_label}>用户邮箱</div>
            <input
              disabled={!showEdit}
              className={!showEdit ? styles.info_input : ""}
              value={email || ""}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
                userEdit.current.email = e.currentTarget.value;
              }}
              placeholder="请输入您的邮箱"
            />
          </div>
          <div className={styles.info_part}>
            <div className={styles.info_label}>手机号</div>
            <input
              disabled={!showEdit}
              className={!showEdit ? styles.info_input : ""}
              value={phone || ""}
              onChange={(e) => {
                setPhone(e.currentTarget.value);
                setChangePhone(true);
                userEdit.current.phone_number = e.currentTarget.value;
              }}
              placeholder="请输入您的手机号"
            />
          </div>
          {showEdit && changePhone && (
            <div className={styles.info_part}>
              <div className={styles.info_label}>验证码</div>
              <input
                value={code || ""}
                onChange={(e) => {
                  setCode(e.currentTarget.value);
                  userEdit.current.sms_code = e.currentTarget.value;
                }}
                placeholder="请输入短信验证码"
                type="text"
              />
              <div className={styles.send_code} onClick={() => getCode()}>
                发送验证码
              </div>
            </div>
          )}
          <div className={styles.info_part}>
            <div className={styles.info_label}>密码</div>
            <input
              disabled={!showEdit}
              className={!showEdit ? styles.info_input : ""}
              value={showEdit ? password : "******"}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
              placeholder="请输入您的密码"
            />
          </div>
          {showEdit && (
            <div className={styles.info_part}>
              <div className={styles.info_label}>密码确认</div>
              <input
                value={passwordSure || ""}
                onChange={(e) => {
                  setPasswordSure(e.currentTarget.value);
                }}
                placeholder="请确认您的密码"
                type={password_iconshow ? "text" : "password"}
              />
              <div
                className={styles.icons}
                onClick={() => setPassword_iconshow(!password_iconshow)}
              >
                {!password_iconshow ? (
                  <EyeInvisibleOutlined style={{ fontSize: 24 }} />
                ) : (
                  <EyeOutlined style={{ fontSize: 24 }} />
                )}
              </div>
            </div>
          )}
          {!showEdit && (
            <div className={styles.third_part}>
              <span>第三方应用：</span>
              <img
                src={
                  userInfo.is_bind_wechat
                    ? "/setting/wechat.png"
                    : "/setting/wechat_hui.png"
                }
              ></img>
              <img
                onClick={goGithub}
                src={
                  userInfo.is_bind_github
                    ? "/setting/github.png"
                    : "/setting/github_hui.png"
                }
              ></img>
            </div>
          )}
          {showEdit && (
            <div className={styles.saveBtn} onClick={saveInfo}>
              保存
            </div>
          )}
          {!showEdit && (
            <div
              className={styles.saveBtn}
              onClick={() => {
                setShowEdit(true);
              }}
            >
              修改
            </div>
          )}
        </div>
      )}
      {active == 1 && (
        <div className={styles.pay}>
          <div className={styles.method}>
            <div className={styles.title}>支付方式：</div>
            <Radio.Group onChange={onPayTypeChange} value={payType}>
              <Radio
                value={"1"}
                style={{
                  marginRight: "24px",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                <div className={styles.payType}>
                  <div className={styles.ali} />
                  <span>支付宝</span>
                </div>
              </Radio>
              <Radio
                value={"2"}
                style={{
                  marginRight: "24px",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                <div className={styles.payType}>
                  <div className={styles.wx} />
                  <span>微信</span>
                </div>
              </Radio>
            </Radio.Group>
          </div>
          <div className={styles.order}>
            <div className={styles.title}>会员类型：</div>
            <div>
              <Radio.Group
                onChange={onChange}
                className={styles.orderTypes}
                value={orderType}
              >
                {productList.map((item) => {
                  return (
                    <Radio
                      key={item.uuid}
                      onClick={() => setOrderItem(item)}
                      value={item.alias}
                      style={{ fontSize: 16 }}
                    >
                      {item.description}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
          </div>
          <div className={styles.price}>
            <div className={styles.title}>支付金额：</div>
            {orderType && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {orderItem?.price && (
                  <span
                    className={
                      orderItem?.discount_price ? styles.originalPrice : ""
                    }
                  >
                    {orderItem?.price}元
                  </span>
                )}
                {orderItem?.discount_price && (
                  <div>
                    <span>限时特价</span>
                    {orderItem?.discount_price}元
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <p className={styles.tips}>*请点击绑定跳转支付宝授权页面进行绑定</p> */}
          {payType && orderType && (
            <div>
              <button onClick={bindCard} className={styles.bindCard}>
                支付
              </button>
            </div>
          )}
        </div>
      )}
      {active == 2 && (
        <div className={styles.log}>
          <div className={styles.table}>
            <table>
              <thead>
                <tr>
                  <th>支付时间</th>
                  <th>支付方式</th>
                  <th>支付金额</th>
                  <th>支付渠道</th>
                  <th>支付状态</th>
                </tr>
              </thead>
              <tbody>
                {logList.map((item: any, index: any) => {
                  return (
                    <tr key={item.uuid}>
                      <td>{item.timestamp}</td>
                      <td>{item.payment_type}</td>
                      <td>{item.amount}</td>
                      <td>{item.payment_method}</td>
                      <td>{item.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={styles.page_tit}>
            <div>数据 {count}</div>
            {pageCount > 1 && (
              <Page
                {...{
                  pageCount,
                  isDialog: true,
                  currentPage: page_index,
                  initPatient,
                }}
              ></Page>
            )}
          </div>
        </div>
      )}
      <Modal
        title="微信支付"
        open={wxPayOpen}
        okText={"支付完成"}
        width={400}
        centered
        onCancel={() => {
          setWxPayOpen(false);
        }}
        onOk={() => {
          setWxPayOpen(false);
          Router.reload();
        }}
      >
        <div className={styles.QRCodeBox}>
          <QRCode status={QRStatus} value={QRUrl} size={200} errorLevel="H" />
        </div>
      </Modal>
    </div>
  );
}
