import { useEffect, useState,useRef } from "react";
const Page = (props: any) => {
    const [value, setValue] = useState('');
    const [showLeft, setShowLeft] = useState(true);
    const [showRight, setShowRight] = useState(true);
    const [pageLeft, setPageLeft] = useState<any[]>([]);
    const [pageMiddle, setPageMiddle] = useState([]);
    const [pageRight, setPageRight] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const hasPre = false;
    const showLeftDot = false;
    const showRightDot = false;
    const hasNext = true;

   // let currentPage = props.currentPage
    // 分页功能: num: currentPage
    useEffect(()=>{
        initPagination(props.currentPage);
    },[])
    const initPagination = (num: number) => {
        setCurrentPage(num);
        if (props.isDialog) {
            initPage(num);
        }
    }
    // @ts-ignore
    const initPage = (num: number) => {
        const count = props.pageCount;
        setCurrentPage(num)
        if (count === 1) {
            // 只有一页，不显示分页
            setPageLeft([]);
            setPageRight([]);
            setPageMiddle([]);
        } else if (count <= 5) { // 页数大于1
            setShowLeft(false);
            setShowRight(false);
            for (let i = 1; i <= count; i++) {
                if (pageLeft.length <= 4) {
                    // @ts-ignore

                    setPageLeft(pageLeft=>[...pageLeft, i])
                } else {
                    return false;
                }
            }
        } else {
            setShowLeft(false);
            const arr:Array<any>=[]
            for (let i = 1; i <= count; i++) {
                if (arr.length <= 4) {
                    // @ts-ignore
                    arr.push(i)
                } else {
                    setPageLeft(arr)
                    return false;
                }  
            }
        }
    }
    // 跳转页面 num: currentPage
    const goPage = (num: number | undefined) => {
        if (Number(num)) {
            setCurrentPage(Number(num))
            if (props.isDialog) {
                // const remain = this.currentPage % 5;
                // if (this.pageCount === 5) {
                //   this.showLeft = false;
                //   this.showRight = false;
                //   this.pageLeft = [this.currentPage - 4, this.currentPage - 3, this.currentPage - 2, this.currentPage - 1, this.currentPage];
                // }else if (this.pageCount < 5){
                //
                // }else if (this.pageCount > 5){
                // }
                // if (remain === 0 ) {
                //   if (this.currentPage === 5 && this.currentPage !== this.pageCount) {
                //     this.showLeft = false;
                //     this.showRight = true;
                //   } else if (this.currentPage !== 5 && this.currentPage !== this.pageCount) {
                //     this.showLeft = true;
                //     this.showRight = true;
                //   }else if (this.currentPage !== 5 && this.currentPage === this.pageCount) {
                //     this.showLeft = true;
                //     this.showRight = false;
                //   }
                //   this.pageLeft = [this.currentPage - 4, this.currentPage - 3, this.currentPage - 2, this.currentPage - 1, this.currentPage];
                // }else if (remain === 1) {
                //   // 当前点击的为第一页
                //   if (this.currentPage === 1) {
                //     // 总页数为5时
                //     if ( this.pageCount === 5) {
                //       this.showLeft = false;
                //       this.showRight = false;
                //       this.pageLeft = [this.currentPage , this.currentPage + 1 , this.currentPage + 2, this.currentPage + 3,
                //         this.currentPage + 4];
                //     }else if (this.pageCount < 5) {
                //       // 总页数不为5时
                //       this.showLeft = false;
                //       this.showRight = false;
                //       this.pageLeft = [this.currentPage , this.currentPage + 1 , this.currentPage + 2, this.currentPage + 3];
                //     }
                //   } else if (this.currentPage !== 1 ) {
                //     // 当前点击的不是第一页
                //     if (this.currentPage === this.pageCount) {
                //       this.showLeft = true;
                //       this.showRight = false;
                //       this.pageLeft = [this.currentPage];
                //     } else if (this.currentPage + 1 === this.pageCount) {
                //       this.showLeft = true;
                //       this.showRight = false;
                //       this.pageLeft = [this.currentPage, this.currentPage + 1];
                //     } else if (this.currentPage + 2 === this.pageCount) {
                //       this.showLeft = true;
                //       this.showRight = false;
                //       this.pageLeft = [this.currentPage, this.currentPage + 1, this.currentPage + 2];
                //     } else if (this.currentPage + 2 < this.pageCount) {
                //       this.showLeft = true;
                //       this.showRight = true;
                //       this.pageLeft = [this.currentPage, this.currentPage + 1, this.currentPage + 2];
                //     }
                //   }
                // }else if (remain === 2) {
                //   if (this.currentPage === 2) {
                //     if (this.currentPage === this.pageCount) {
                //       this.showLeft = false;
                //       this.showRight = false;
                //       this.pageLeft = [this.currentPage - 1 , this.currentPage];
                //     } else if (this.currentPage + 1 === this.pageCount) {
                //       this.showLeft = false;
                //       this.showRight = false;
                //       this.pageLeft = [this.currentPage - 1 , this.currentPage  , this.currentPage + 1];
                //     }else if (this.currentPage + 1 < this.pageCount) {
                //       this.showLeft = false;
                //       this.showRight = true;
                //       this.pageLeft = [this.currentPage - 1 , this.currentPage  , this.currentPage + 1];
                //     }
                //   }else if (this.currentPage !== 2) {
                //     if (this.currentPage + 1 === this.pageCount) {
                //       this.showLeft = true;
                //       this.showRight = false;
                //       this.pageLeft = [this.currentPage - 1 , this.currentPage  , this.currentPage + 1];
                //     }else if (this.currentPage === this.pageCount) {
                //       this.showLeft = true;
                //       this.showRight = false;
                //       this.pageLeft = [this.currentPage - 1 , this.currentPage ];
                //     }else if (this.currentPage + 1 < this.pageCount) {
                //       this.showLeft = true;
                //       this.showRight = true;
                //       this.pageLeft = [this.currentPage - 1 , this.currentPage  , this.currentPage + 1];
                //     }
                //   }
                // }
            }
            // else {
            //   this.initPagination(num);
            // }
            
            props.initPatient(num);
        }
    }
    // 输入跳转
    const inputPage = () => {
        const pageIndex = parseInt(value);
        if (pageIndex > 0 && pageIndex <= props.pageCount) {
            goPage(Number(value));
        } else {
            props.showTips('您输入的页码无效');
        }
    }
    // 上一页
    const prevPage = () => {
        if (props.isDialog) {
            goPrevPage();
        }
    }
    // 下一页
    const nextPage = () => {
        if (props.isDialog) {
            goNextPage();
        }
    }
    // 弹框内分页样式
    // @ts-ignore
    const goPrevPage = () => {
        if (pageLeft[0] === 1) {
            return false;
        } else {
            const num = pageLeft[0];
            if (num === 6) {
                setShowLeft(false)
            } else {
                setShowLeft(true)
            }
            // this.showRight = true;
            setPageLeft([])
            let arr:Array<any>=[]
            for (let i = num - 1; i <= props.pageCount; i++) {
                if (arr.length <= 4) {
                    // @ts-ignore
                    arr = [num - 5, num - 4, num - 3, num - 2, num - 1];
                    if (arr.length === 5 && arr[4] !== props.pageCount) {
                        setShowRight(true)
                    } else {
                        setShowRight(false)
                    }
                } else {
                    setPageLeft(arr)
                    return false;
                }
            }
        }
    }
    // @ts-ignore
    const goNextPage = () => {
        if (pageLeft.length === 5 && props.pageCount > 5) {
            setShowLeft(true);
            const num = pageLeft[pageLeft.length - 1];
            setPageLeft([])
            const arr=[]
            for (let i = num + 1; i <= props.pageCount; i++) {
                if (arr.length <= 4) {
                    // @ts-ignore
                    arr.push(i);
                } else {
                    setPageLeft(arr)
                    return false;
                }
            }
            setPageLeft(arr)
            if (Number(arr[arr.length - 1]) === props.pageCount) {
                setShowRight(false);
            }
            // if (this.pageLeft.length < 5 ) {
            //   this.showRight = false;
            // }else {
            //   this.showRight = true;
            // }
        }
    }

    return (
        <>
            <div className="nav clearfix">
                <ul className="pagination clearfix">
                    {showLeft && 
                    <li className={["item", "prev", !hasPre ? 'item-none' : ''].join(' ')} 
                        style={{ background: "none", border: "none" }} 
                        onClick={prevPage}>
                        <i style={{ transform: "rotate(180deg)" }}></i>
                    </li>}
                    {/* <li className={["item", "prev"].join(' ')} style={{ background: "#001B35", border: "none" }} onClick={prevPage}>
                        <i style={{ transform: "rotate(180deg)" }}></i>
                    </li> */}
                    {pageLeft.map(num => {
                        return (
                            <li key={num} className={["item", currentPage == num ? 'item-active' : ''].join(' ')} onClick={() => goPage(num)}>{num}</li>
                        )
                    })}
                    {showLeftDot && <li className="item dotted">...</li>}
                    {
                        pageMiddle.map(num => {
                            return (
                                <li key={num} className={["item", currentPage == num ? 'item-active' : ''].join(' ')} onClick={() => goPage(num)}>{num}</li>
                            )
                        })
                    }

                    {showRightDot && <li className={"item dotted"}>...</li>}

                    {pageRight.map(num => {
                        return (
                            <li key={num} className={["item", currentPage == num ? 'item-active' : ''].join(' ')} onClick={() => goPage(num)}>{num}</li>
                        )
                    })
                    }

                    {showRight && <li className={["item next", !hasNext ? 'item-none' : ''].join(" ")} style={{ background: "none", border: "none" }}   onClick={nextPage}>
                        <i></i>
                    </li>}
                </ul>
            </div>

            <style jsx>{`   
    body, html, p, button, ul, p, h1, h2, h3, h4, li, input, button{
        margin: 0;
        padding: 0;
      }
      input, button {
        border: 0;
        outline: none;
      }
      .nav {
        position: relative;
        // left: 50%;
        display: flex;
        justify-content: center;
      }
      .pagination {
        float: left;
      }
      li.item::marker {
        color: transparent;
      }
      .pagination .item {
        width: 43px;
        height: 42px;
        background: #fff;
        line-height: 42px;
        border: 1px solid rgba(0,0,0,0.15);
        border-radius: 4px;
        color: rgba(0,0,0,0.65);
        font-size: 12px;
        text-align: center;
        float: left;
        margin-right: 10px;
        cursor: pointer;
        list-style: none
      }
      .pagination .item-active {
        border: 1px solid #2B63E3;
        background: #2B63E3;
        color: #fff;
      }
      .pagination .item-none {
        color: #DFDFDF;
      }
      .item i {
        display: inline-block;
        width:44px;
        height:42px;
        vertical-align: middle;
        background: url("/page/back.png") no-repeat;
      }
      .pageNum {
        float: left;
        line-height: 0;
        /*margin: 16px 0;*/
        span {
          color: #788993;
        }
      }
      .pageNum input {
        width: 30px;
        height: 24px;
        background: #000E1E;
        border-radius: 4px;
        text-align: center;
        margin: 0 8px;
        color:#fff;
      }
      button {
        width: 42px;
        height: 24px;
        color: #fff;
        background: #00549D;
        border-radius: 4px;
        margin: 0 10px;
      }
      
    `
            }

            </style>
        </>
    )
}

export default Page