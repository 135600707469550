import styles from "../styles/chat.module.scss";
import { useEffect, useRef, useState } from "react";
import Router, { useRouter } from "next/router";
import http from "../http";
import Setting from "../components/Setting";
import { useUserInfo } from "../context/ThemeContext";
import { ShowMessage } from "../components/ShowMessage/ShowMessage";
import LeftPannel from "../components/common/LeftPannel";
import LoginBox from "../components/common/LoginBox";
import ChatpdfBox from "../components/common/ChatpdfBox";
import { TreeData } from "../components/tree/CodeTree";
import Login from "../components/common/login";
import { Spin } from "antd";
import LoadingIcon from "../components/Icon/LoadingIcon";
export default function Index() {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [settingShow, setSettingShow] = useState(false);
  const { userInfo, toggleUserInfo } = useUserInfo();
  const loginBox = useRef<any>();
  const login = useRef<any>();
  const [visit_page, setVisit_page] = useState(0);
  const chatBox = useRef<any>();
  const [treeData, setTreeData] = useState<TreeData[]>([]);
  const [codeData, setCodeData] = useState<{
    branch_name: string;
    repo_full_name: string;
  }>({
    branch_name: "",
    repo_full_name: "",
  });
  const [isoutputing, setIsoutputing] = useState<boolean>(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!userInfo?.phone_number && !token) {
      openNotification();
    } else {
      // 页面刷新之后取消threadId的连接
      const ids = JSON.parse(localStorage.getItem("threadIds") || "[]");
      ids.forEach(async (id: string) => {
        await reLoadCancleChat(id);
      });
      localStorage.setItem("threadIds", JSON.stringify([]));
      getInfo();
    }
  }, []);

  useEffect(() => {
    //debugger
    if (router.isReady) {
      const token = localStorage.getItem("token");
      // 路由参数已准备好，可以进行操作
      router.query.github_id && setSettingShow(true);
      router.query?.bd_vid &&
        sessionStorage.setItem(
          "bd_vid",
          router.query?.bd_vid?.toString() || ""
        );
      router.query?.utm_source &&
        sessionStorage.setItem(
          "utm_source",
          router.query?.utm_source.toString()
        );

      if (userInfo.phone_number && token) {
        if (!router.query.thread_id) {
          chatBox.current?.createThread();
        } else {
          chatBox.current?.getChatLog(router.query.thread_id);
        }
      }
    }
  }, [router.isReady, userInfo]);

  const getInfo = () => {
    setIsLoading(true);
    http.post(`/api/v1/account/info`).then((data: any) => {
      setIsLoading(false);
      if (data.status) {
        if (data.data.subscription_expired) {
          const [datePart] = data.data.subscription_expired.split(" ");
          data.data.subscription_expired = datePart;
        }
        toggleUserInfo(data.data);
        login.current.close();
      } else {
        ShowMessage.error(data.message);
      }
    });
  };

  const chatItemClick = (thread_id: string) => {
    setSettingShow(false);
    chatBox.current?.getChatLog(thread_id);
  };

  const createNewChat = async () => {
    if (!userInfo.phone_number) {
      login.current.show();
      return;
    }

    setSettingShow(false);
    setIsLoading(true);
    chatBox.current.createNewChat();
  };

  const cancleChat = (thread_id: string) => {
    //取消与后端的聊天
    return new Promise((resolve, reject) => {
      http
        .post("api/v1/chat/cancel", { thread_id: thread_id })
        .then((data: any) => {
          if (data.status) {
            resolve(true);
          } else {
            resolve(false);
            ShowMessage.error(data.message);
          }
        })
        .finally(() => {
          setIsoutputing(false);
        });
    });
  };
  // 需要传id
  const reLoadCancleChat = (id: string) => {
    //取消与后端的聊天
    return new Promise((resolve, reject) => {
      http
        .post("api/v1/chat/cancel", { thread_id: id })
        .then((data: any) => {
          if (data.status) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .finally(() => {
          setIsoutputing(false);
        });
    });
  };

  const handleSetting = () => {
    setSettingShow(!settingShow);
    setVisit_page(0);
  };
  const gotoPay = () => {
    setSettingShow(!settingShow);
    setVisit_page(1);
  };
  const openNotification = () => {
    setSettingShow(false);
    loginBox.current.showLogin();
  };
  const loginSucccess = () => {
    getInfo();
  };
  return (
    <>
      <div className={styles.chatpdf} key={userInfo.phone_number}>
        <LeftPannel
          treeData={treeData}
          isoutputing={isoutputing}
          codeData={codeData}
          createNewChat={createNewChat}
          chatItemClick={chatItemClick}
          settingClick={handleSetting}
          gotoPay={gotoPay}
          logOut={() => {
            setTimeout(() => {
              openNotification();
            }, 100);
          }}
          handleLogin={() => {
            login.current.show();
          }}
        ></LeftPannel>
        <ChatpdfBox
          visible={!settingShow}
          reLoadCancleChat={reLoadCancleChat}
          cancleChat={cancleChat}
          getInfo={getInfo}
          setLoading={(isLoading) => {
            setIsLoading(isLoading);
          }}
          setSettingShow={(is) => {
            setSettingShow(is);
          }}
          threadId={router.query.thread_id?.toString() || ""}
          setData={(treeData, codeData, isoutputing) => {
            setTreeData(treeData);
            setCodeData(codeData);
            setIsoutputing(isoutputing);
          }}
          gotoLogin={() => {
            login.current.show();
          }}
          ref={chatBox}
        />
        <Setting
          visible={settingShow}
          visit_page={visit_page}
          getInfo={getInfo}
          userInfo={userInfo}
          setIsLoading={setIsLoading}
        ></Setting>
      </div>
      <LoginBox
        ref={loginBox}
        gotoLogin={(openId) => {
          login.current.show(openId);
        }}
        loginSuccess={loginSucccess}
      />
      <Login ref={login} loginSucccess={loginSucccess}></Login>
      
      <Spin
        spinning={isLoading}
        indicator={<LoadingIcon size={48} />}
        fullscreen
      />
    </>
  );
}
